//Helper to determine if old IE to avoid JS error
function is_IE() {
  return (window.navigator.userAgent.match(/MSIE|Trident/) !== null);
}
(function($) {
  //Docready
  $(function() {
    //Accessbility Enhancement
      //Listen for Tab keypress in order to update outline styling on dash pleats to improve keyboard naivagation for better accessibility
      $(document).on( 'keyup', function( e ) {
        if( e.which == 9 ) {
            //console.log( 'tab pressed' );
            var div = $("<div />", {
                html: '&shy;<style>[id$=dashbarWrapper] h3 > button:focus, .dashbar-wrapper h3 > button:focus, *:focus{outline: 2px solid #CC00C5;}</style>'
            }).appendTo("head");
        }
      });

    //animate scrolling to join form
    var $scrollPoint = $("#join");
    var scrollSpeed;
    // ref - https://balupton.github.io/jquery-scrollto/
    $("[id*='js-scrollTo']").on("click", function() {

      if($(this).attr('id') == 'js-scrollTo2' || 'js-scrollTo3') {
          scrollSpeed = 1400;
      } else if ($(this).attr('id') == 'js-scrollTo4', 'js-scrollTo5') {
        scrollSpeed = 1800;
      } else if ($(this).attr('id') == 'js-scrollTo6') {
        scrollSpeed = 1900;
      } else {
        scrollSpeed = 1000;
      }
      $("html, body").animate({
          scrollTop: $($scrollPoint).offset().top
        }, scrollSpeed);
      //send event to GA
      if (typeof gtag !== 'undefined') {
        //send event to GA
        gtag('event', 'click', {
            'event_category': 'Scroll',
            'event_label': 'Scroll Button #' + $(this).attr('id').match(/\d+/)[0],
        });
      }
    });
    //Forms
    //input animation on focus of input[text]
    // $(".form-row input").on("focus", function() {
    //   $(".form-row:not(this)")
    //     .children()
    //     .removeClass("active");
    //   $(this).addClass("active");
    //   $(this)
    //     .siblings()
    //     .addClass("active");
    // });

    // Add text, email, tel field active state on blur and keyup
    $(".form-row input[type=text], .form-row input[type=email], .form-row input[type=tel]").on("blur keydown input", function() {
        // add active state
        $(this).addClass("active");
        $(this).siblings().addClass("active");
        // check for value
        if($(this).val().length > 0) {
          //if value persist active state
          $(this).addClass("active");
          $(this).siblings().addClass("active");
        } else {
          $(this).removeClass("active");
          $(this).siblings().removeClass("active");
        }
    });
    //Send event to GA for each form field that is get a value to show abandonment
    $("input")
      .on("blur", function() {
        if ($(this).val().length > 0) {
          var attrName = $(this).attr("name");
          //send event to GA
          if (typeof gtag !== 'undefined') {
            //send event to GA
            gtag('event', 'input', {
                'event_category': 'Form',
                'event_label': attrName,
            });
          }
        }
      });
    //If Subscribe checkbox is checked update hidden input for broadcast email
    // Send event to GA
    $("#isSubscribed").on("click", function() {
      if ($("#isSubscribed").hasClass("checked")) {
        $("#isSubscribed").removeClass("checked");
        $("input#BroadcastEmail").val("");
        $("input#NewsletterStatus").val("NOT_SUBSCRIBED");
      } else {
        $("#isSubscribed").addClass("checked");
        $("input#BroadcastEmail").val($("input#Email").val());
        $("input#NewsletterStatus").val("OPT_IN");
        if (typeof gtag !== 'undefined') {
          //send event to GA
          gtag('event', 'input', {
              'event_category': 'Form',
              'event_label': 'Subscribed Broadcast',
          });
        }
        if(!is_IE()){
          fbq('trackCustom', 'Form', {
              action: 'Subscribed',
          });
        };
      }
    });
    /******************TOGGLE PHONE & MOBILE REQUIRED ATTR**********************/
    //validate phone and mobile to ensure one of the fields have a value
    // $('input[type=tel]').on('blur', function () {

    //     var $form = $('#memberForm');

    //     var $phone = $form.find('input[name=DayPhone]');
    //     var $mobile =  $form.find('input[name=Mobile]');

    //     if ($phone.val() === '' && $mobile.val() === '') {

    //       $phone.attr('required', true).attr('aria-required', true);
    //       $mobile.attr('required', true).attr('aria-required', true);

    //     } else if ($phone.val() !== '' && $mobile.val() === '' ) {

    //       $phone.attr('required', true).attr('aria-required', true);
    //       $mobile.removeAttr('required aria-required');

    //     } else if ($mobile.val() !== '' && $phone.val() === '') {

    //       $mobile.attr('required', true).attr('aria-required', true);
    //       $phone.removeAttr('required aria-required');

    //     }

    // });

    /**********************HANDLE FORM SUBMISSION *****************************/
    // Scroll to top of form to make all erros visible
    $("#memberForm")
      .parsley()
      .on("form:error", function() {
        var $scrollPoint = $("#join");
        $("html, body").animate(
          {
            scrollTop: $($scrollPoint).offset().top
          },
          500
        );
      });
    // Add values to hidden inputs before submit
    $("#memberForm")
      .parsley()
      .on("form:success", function() {
        var $form = $("#memberForm");
        //Concatinate Firstname & Lastname into AccountName + MailName
        var fname = $form.find("input[name=Firstname]").val();
        var lname = $form.find("input[name=Lastname]").val();
        $form.find("input[name=Salutaion]").val(fname);
        $form.find("input[name=Firstname1]").val(fname);
        $form.find("input[name=Salutation]").val(fname);
        $form.find("input[name=Lastname1]").val(lname);
        $form.find("input[name=AccountName]").val(fname + " " + lname);
        $form.find("input[name=MailName]").val(fname + " " + lname);
        //add DayPhone to HomePhone hidden input
        $form
          .find("input[name=HomePhone]")
          .val($form.find("input[name=DayPhone]").val());
        //if subscribed to special offers update BroadcastEmail hidden input - else clear hidden input
        if ($("#isSubscribed").hasClass("checked")) {
          $form
            .find("input[name=BroadcastEmail]")
            .val($("input[name=Email]").val());
          $form.find("input#NewsletterStatus").val("OPT_IN");
        } else {
          $form.find("input[name=BroadcastEmail]").val("");
          $form.find("input#NewsletterStatus").val("NOT_SUBSCRIBED");
        }
        console.log ('Salutaion:' + $form.find('input[name=Salutaion]').val() +', Account Name:'+ $form.find('input[name=AccountName]').val() +', MailName:'+ $form.find('input[name=MailName]').val() + ', Firstname1:'+ $form.find('input[name=Firstname1]').val() + ', Lastname1:' + $form.find('input[name=Lastname1]').val() +', DayPhone:' + $form.find('input[name=DayPhone]').val() +', NewsletterStatus:'+ $form.find('input#NewsletterStatus').val());
      });
    // submit form. animate submit button. ajax request post to form-handler.php. handle errors
    $("#memberForm")
      .parsley()
      .on("form:submit", function() {
        var $submitButton = $("#submit-button");
        var $spinnerButton = $("#submit-button #spinner span");
        var $checkButton = $("#submit-button #check span");

        $submitButton.attr("disabled", true);
        $submitButton.toggleClass("loading");
        $spinnerButton.attr('aria-hidden', false);
        $spinnerButton.attr('role', 'alert');
        $checkButton.attr('aria-hidden', false);
        $checkButton.attr('role', 'alert');

        var $form = $("#memberForm");
        console.log('AccountName:' + $form.find('input[name=AccountName]').val() + ','+ 'Address5:' + $form.find('select[name=Address5]').val() + ','+ 'BroadcastEmail:' + $form.find('input[name=BroadcastEmail]').val() + ','+ 'DayPhone:' + $form.find('input[name=DayPhone]').val() + ','+ 'Email:' + $form.find('input[name=Email]').val() + ','+ 'Salutation:' + $form.find('input[name=Salutation]').val() + ','+ 'Firstname1:' + $form.find('input[name=Firstname1]').val() + ','+ 'HomePhone:' + $form.find('input[name=HomePhone]').val() + ','+ 'Lastname1:' + $form.find('input[name=Lastname1]').val() + ','+ 'Mailname:' + $form.find('input[name=Mailname]').val() + ','+ 'Mobile:' + $form.find('input[name=Mobile]').val() + ','+ 'NewsletterStatus:' + $form.find('input[name=NewsletterStatus]').val() + ','+ 'ReferralID:' + $form.find('input[name=ReferralID]').val() + ','+ 'MailOut:' + $form.find('input[name=MailOut]').val() + ','+ 'ResortName:' + $form.find('input[name=ResortName]').val() + ','+ 'ResortMemberNo:' + $form.find('input[name=ResortMemberNo]').val() + ','+ 'EndpointID:' + $form.find('input[name=EndpointID]').val() + ','+ 'AnniversaryDate:' + $form.find('input[name=AnniversaryDate]').val() + ','+ 'UnitTypeDesc:' + $form.find('input[name=UnitTypeDesc]').val() + ','+ 'UnitTypeID:' + $form.find('input[name=UnitTypeID]').val() + ','+ 'UnitSleeps:' + $form.find('input[name=UnitSleeps]').val() + ','+ 'OwnershipType:' + $form.find('input[name=UnitSleeps]').val() + ','+ 'WeekNo:' + $form.find('input[name=WeekNo]').val() + ','+ 'ExpiryDate:' + $form.find('input[name=ExpiryDate]').val());

        var countryName = $form.find("select[name=Address5]").val();
        var countryIndex = COUNTRY_LIST.indexOf(countryName);
        if (countryIndex > -1) {
          $("input[name=OfficeCode]").val(OFFICE_CODE[countryIndex]);
        }

        console.log('ReferralID:' + $form.find('input[name=ReferralID]').val());

        $.ajax({
          method: "POST",
          url: "../form_handlers/form-handler.php",
          data: {
            //location info
            RegionCode: window.regionCode,

            //membership
            AccountName: $form.find("input[name=AccountName]").val(),
            Address5: countryName,
            BroadcastEmail: $form.find("input[name=BroadcastEmail]").val(),
            DayPhone: $form.find("input[name=DayPhone]").val(),
            Email: $form.find("input[name=Email]").val(),
            Salutation: $form.find("input[name=Salutation]").val(),
            Firstname1: $form.find("input[name=Firstname1]").val(),
            HomePhone: $form.find("input[name=HomePhone]").val(),
            Lastname1: $form.find("input[name=Lastname1]").val(),
            Mailname: $form.find("input[name=Mailname]").val(),
            Mobile: $form.find("input[name=Mobile]").val(),
            NewsletterStatus: $form.find("input[name=NewsletterStatus]").val(),
            ReferralID: $form.find('input[name=ReferralID]').val(),
            MailOut: $form.find("input[name=MailOut]").val(),
            OfficeCode: $form.find("input[name=OfficeCode]").val(),
            PostCode: $form.find("input[name=PostCode]").val(),

            //ownership
            ResortName: $form.find("input[name=ResortName]").val(),
            ResortMemberNo: $form.find("input[name=ResortMemberNo]").val(),
            EndpointID: $form.find("input[name=EndpointID]").val(),
            AnniversaryDate: $form.find("input[name=AnniversaryDate]").val(),
            UnitTypeDesc: $form.find("input[name=UnitTypeDesc]").val(),
            UnitTypeID: $form.find("input[name=UnitTypeID]").val(),
            UnitSleeps: $form.find("input[name=UnitSleeps]").val(),
            OwnershipType: $form.find("input[name=UnitSleeps]").val(),
            WeekNo: $form.find("input[name=WeekNo]").val(),
            // Comments: $form.find('input[name=Comments]').val(),
            ExpiryDate: $form.find("input[name=ExpiryDate]").val(),
            PreferredLangCode: $form.find("select[name=PreferredLangCode]").val()
          }
        })
          .done(function(data) {
            var jsonData = JSON.parse(data);

            var returnCode = parseInt(jsonData.ReturnCode, 10);

            if (returnCode === 0) {
              //console.log(data);

              location = "/thankyou.html";

              //send GA event
              if (typeof gtag !== 'undefined') {
                //send event to GA
                gtag('event', 'submit', {
                    'event_category': 'Form',
                    'event_label': 'Form Submitted',
                });
              }
              // Send FB event
              if(!is_IE()){
                fbq('trackCustom', 'Form', {
                    action: 'Submitted',
                  });
              };
            } else if (returnCode === 104) {
              //console.log('user exist', data);
              location = "/error_user_exists.html";

              if (typeof gtag !== 'undefined') {
                //send event to GA
                gtag('event', 'error', {
                    'event_category': 'Form',
                    'event_label': 'Form Error - Duplicate Member',
                });
              }
              // Send FB event
              if(!is_IE()){
                fbq('trackCustom', 'Form', {
                    action: 'Form Error - Duplicate Member',
                  });
              };
            } else if (returnCode === 103) {
              //console.log('user exist', data);
              location = "/error_user_exists.html";

              if (typeof gtag !== 'undefined') {
                //send event to GA
                gtag('event', 'error', {
                    'event_category': 'Form',
                    'event_label': 'Form Error - Duplicate Member',
                });
              }
              // Send FB event
              if(!is_IE()){
                fbq('trackCustom', 'Form', {
                    action: 'Form Error - Duplicate Member',
                  });
              };
            } else {
              //console.log('err', data);
              location = "/error.html";

              if (typeof gtag !== 'undefined') {
                //send event to GA
                gtag('event', 'error', {
                    'event_category': 'Form',
                    'event_label': 'Form Error',
                });
              }
            }
          })
          .fail(function(error) {
            //console.log('fail', error);
            location = "/error.html";
            if (typeof gtag !== 'undefined') {
              //send event to GA
              gtag('event', 'error', {
                  'event_category': 'Form',
                  'event_label': 'Form Error',
              });
            }
          })
          .always(function() {
            $submitButton.toggleClass("done");
            $submitButton.attr("disabled", false);
          });

        return false; //stop form submission
      });
    /**********************END HANDLE FORM SUBMISSION *****************************/
  }); // End Doc Ready
})(window.jQuery || window.Zepto); //End IFFE
